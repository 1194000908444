




























import { defineComponent } from "@vue/composition-api";
import { auth, providers } from "@/services/firebase";
import { useNotifier } from "@/providers/notifier";

export default defineComponent({
  name: "LinkProviders",
  setup: () => {
    const notify = useNotifier();
    const currentUser = auth.currentUser;

    async function linkWith(providerType: "facebook" | "google") {
      if (providerType === "facebook") {
        const facebookProvider = new providers.FacebookAuthProvider();
        facebookProvider.addScope("public_profile");
        facebookProvider.addScope("user_birthday");
        facebookProvider.addScope("user_gender");
        facebookProvider.addScope("user_hometown");
        facebookProvider.addScope("user_likes");
        facebookProvider.addScope("user_link");
        facebookProvider.addScope("user_location");
        await currentUser?.linkWithPopup(facebookProvider);
        notify({
          title: "🥳",
          message: "Du kan nå logge inn med Facebook.",
          type: "success"
        });
      }
      else if (providerType === "google") {
        const googleProvider = new providers.GoogleAuthProvider();
        googleProvider.addScope("profile");
        googleProvider.addScope("email");
        await currentUser?.linkWithPopup(googleProvider);
        notify({
          title: "🥳",
          message: "Du kan nå logge inn med Google",
          type: "success"
        });
      }
    }

    const hasProvider = (providerType: "facebook.com" | "google.com") =>
      currentUser?.providerData
        .map((p) => {
          if (p) return p.providerId;
          return "";
        })
        .includes(providerType);

    return {
      hasProvider,
      linkWith,
    };
  },
});
